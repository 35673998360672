import { render, staticRenderFns } from "./blank.vue?vue&type=template&id=2f7ab608&scoped=true"
import script from "./blank.vue?vue&type=script&lang=js"
export * from "./blank.vue?vue&type=script&lang=js"
import style0 from "./blank.vue?vue&type=style&index=0&id=2f7ab608&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f7ab608",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtImage: require('/app/components/NuxtImage.vue').default})
