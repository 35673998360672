const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['bankManagementRedirect'] = require('../middleware/bankManagementRedirect.js')
middleware['bankManagementRedirect'] = middleware['bankManagementRedirect'].default || middleware['bankManagementRedirect']

middleware['noAuth'] = require('../middleware/noAuth.js')
middleware['noAuth'] = middleware['noAuth'].default || middleware['noAuth']

middleware['permissions'] = require('../middleware/permissions.js')
middleware['permissions'] = middleware['permissions'].default || middleware['permissions']

export default middleware
