
export default {
    name: 'NuxtImage',
    props: {
        src: {
            type: String,
            default: null,
        },
        width: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: null,
        },
        fit: {
            type: String,
            default: 'cover',
        },
        styles: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            failed_image: false,
        }
    },
    computed: {
        imageSource() {
            if (
                !this.src ||
                this.src === 'null' ||
                this.src?.split('/')[this.src?.split('/').length - 1] === 'null'
            )
                return '/no-image.png'
            return this.failed_image ? '/no-image.png' : this.src
        },
    },
    methods: {
        onImageError() {
            this.failed_image = true
        },
    },
}
