
export default {
    name: 'Blank',
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: '/csl.png',
                },
            ],
        }
    },
    computed: {
        bgImage() {
            return '/assets/background.jpg'
        },
    },
    mounted() {
        // Remove the no-permission cookies, if user can access this page, it means that user already has permission
        this.$cookie.remove('forbidden_type')
    },
}
