import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a2420cbc&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=a2420cbc&prod&lang=css"
import style1 from "./default.vue?vue&type=style&index=1&id=a2420cbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2420cbc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarChangeLanguage: require('/app/components/navbar/ChangeLanguage.vue').default,NavbarDarkModeSwitch: require('/app/components/navbar/DarkModeSwitch.vue').default,NavbarNotificationList: require('/app/components/navbar/NotificationList.vue').default,NavbarMessageDropdown: require('/app/components/navbar/MessageDropdown.vue').default,NavbarProfileDropdown: require('/app/components/navbar/ProfileDropdown.vue').default,IsOffline: require('/app/components/IsOffline.vue').default,BreadCrumbs: require('/app/components/BreadCrumbs.vue').default,LoadingLanguageString: require('/app/components/LoadingLanguageString.vue').default,QuotaWarning: require('/app/components/QuotaWarning.vue').default,CustomToaster: require('/app/components/CustomToaster.vue').default,VersionUpdateDialog: require('/app/components/VersionUpdateDialog.vue').default})
