// State
export const state = () => ({
    loading: false,
    websiteSettings: [],
	adminRiskControls: {},
    depositTurnovers: [],
    suspendRegistration: [],
    depositFlow: [],
    playerRegistration: [],
    promotionClaimSettingCount: null,
    promotionClaimSettingCountTemp: null, // For cancel button in setting/promotion-claim-setting
    currencies: [],
    recaptchaSetting: {
        is_active: false,
        min_score: 0,
    },

    // cancel token
    settingTabCancelToken: {},
})

// Actions
export const actions = {
    async websiteSettings({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            websiteSettings: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/website-maintenance`, {
                    params,
                    cancelToken:
                        state.settingTabCancelToken.websiteSettings.token,
                })
                .then((response) => {
                    commit('websiteSettings', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async updateWebsiteSetting({ commit }, params) {
        const currency = params.currency
        delete params.currency
        try {
            commit('loading', true)
            return await this.$axios
                .put(`/website-setting/website-maintenance/${currency}`, params)
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async adminRiskControls({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            adminRiskControls: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/admin-risk-control`, {
                    params,
                    cancelToken:
                        state.settingTabCancelToken.adminRiskControls.token,
                })
                .then((response) => {
                    commit('adminRiskControls', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async updateAdminRiskControl({ commit }, params) {
        try {
            commit('loading', true)
            return await this.$axios
                .put(`/website-setting/admin-risk-control`, params)
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async depositTurnovers({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            depositTurnovers: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/deposit-turnover-setting`, {
                    params,
                    cancelToken:
                        state.settingTabCancelToken.depositTurnovers.token,
                })
                .then((response) => {
                    commit('depositTurnovers', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async addDepositTurnover({ commit }, params) {
        try {
            commit('loading', true)
            const currency = params.currency
            delete params.currency
            return await this.$axios
                .post(
                    `/website-setting/deposit-turnover-setting/${currency}`,
                    params
                )
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async updateDepositTurnover({ commit }, params) {
        try {
            commit('loading', true)
            const currency = params.currency
            delete params.currency
            return await this.$axios
                .put(
                    `/website-setting/deposit-turnover-setting/${currency}`,
                    params
                )
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async deleteDepositTurnover({ commit }, params) {
        try {
            commit('loading', true)
            const currency = params.currency
            delete params.currency
            return await this.$axios
                .delete(
                    `/website-setting/deposit-turnover-setting/${currency}`,
                    params
                )
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async suspendRegistration({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            suspendRegistration: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/suspend-registration`, {
                    params,
                    cancelToken:
                        state.settingTabCancelToken.suspendRegistration.token,
                })
                .then((response) => {
                    commit('suspendRegistration', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async updateSuspendRegistration({ commit }, params) {
        try {
            commit('loading', true)
            const currency = params.currency
            delete params.currency
            return await this.$axios
                .put(
                    `/website-setting/suspend-registration/${currency}`,
                    params
                )
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async depositFlow({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            depositFlow: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/deposit-flow-setting`, {
                    params,
                    cancelToken: state.settingTabCancelToken.depositFlow.token,
                })
                .then((response) => {
                    commit('depositFlow', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async updateDepositFlow({ commit }, params) {
        try {
            commit('loading', true)
            const currency = params.currency
            delete params.currency
            return await this.$axios
                .put(
                    `/website-setting/deposit-flow-setting/${currency}`,
                    params
                )
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async playerRegistration({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            playerRegistration: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/player-registration-setting`, {
                    params,
                    cancelToken:
                        state.settingTabCancelToken.playerRegistration.token,
                })
                .then((response) => {
                    commit('playerRegistration', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async updatePlayerRegistration({ commit }, params) {
        try {
            commit('loading', true)
            const currency = params.currency
            delete params.currency
            return await this.$axios
                .put(
                    `/website-setting/player-registration-setting/${currency}`,
                    params
                )
                .then((response) => {
                    commit('loading', false)
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async promotionClaimSetting({ commit, state }, currency) {
        commit('setSettingTabCancelToken', {
            promotionClaimSetting: this.$axios.CancelToken.source(),
        })
        try {
            commit('loading', true)
            return await this.$axios
                .get(`/website-setting/promotion-claim/${currency}`, {
                    cancelToken:
                        state.settingTabCancelToken.promotionClaimSetting.token,
                })
                .then((response) => {
                    commit('promotionClaimSetting', response.data.claim_count)
                    commit(
                        'setPromotionClaimSettingCountTemp',
                        response.data.claim_count
                    )
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
        }
    },
    async updatePromotionSetting({ state, commit, rootGetters }, currency) {
        try {
            commit('loading', true)
            return await this.$axios
                .put(`/website-setting/promotion-claim/${currency}`, {
                    claim_count: state.promotionClaimSettingCount,
                })
                .then(() => {
                    commit('loading', false)
                    commit(
                        'setPromotionClaimSettingCountTemp',
                        state.promotionClaimSettingCount
                    )
                    this.$toast.success(
                        rootGetters['master/storeTl']('updatedSuccessfully')
                    )
                    return true
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async operationSettingCurrencies({ commit, state }, params) {
        commit('setSettingTabCancelToken', {
            operationSetting: this.$axios.CancelToken.source(),
        })
        try {
            return await this.$axios
                .get(`/website-setting/operation-setting/currency`, {
                    params,
                    cancelToken:
                        state.settingTabCancelToken.operationSetting.token,
                })
                .then((response) => {
                    const data = response.data.map((el) => {
                        if (checkNotNull(el.deposit_min))
                            el.deposit_min = parseFloat(el.deposit_min)
                        if (checkNotNull(el.deposit_max))
                            el.deposit_max = parseFloat(el.deposit_max)
                        if (checkNotNull(el.withdraw_min))
                            el.withdraw_min = parseFloat(el.withdraw_min)
                        if (checkNotNull(el.withdraw_max))
                            el.withdraw_max = parseFloat(el.withdraw_max)
                        if (checkNotNull(el.deposit_type))
                            el.deposit_type = parseFloat(el.deposit_type)
                        if (checkNotNull(el.reg_flow))
                            el.reg_flow = parseFloat(el.reg_flow)
                        if (checkNotNull(el.deposit_verification))
                            el.deposit_verification = parseFloat(
                                el.deposit_verification
                            )
                        if (checkNotNull(el.player_reg_id))
                            el.player_reg_id = parseFloat(el.player_reg_id)
                        if (checkNotNull(el.bank_name_type))
                            el.bank_name_type = parseFloat(el.bank_name_type)
                        return el
                    })
                    commit('setCurrencies', data)
                })
        } catch (error) {}
    },
    async saveOperationSetting({ commit }, { currency, form }) {
        try {
            const response = await this.$axios
                .put(`/website-setting/operation-setting/${currency}`, form)
                .then((response) => {
                    return true
                })
                .catch((_) => {
                    return false
                })
            return response
        } catch (error) {}
    },
    async updateOperationStatus({ dispatch }, { currency, status }) {
        try {
            const response = await this.$axios
                .put(
                    `/website-setting/operation-setting/currency/status/${currency}`,
                    {
                        is_active: status,
                    }
                )
                .then((response) => {
                    // After currency status update successfully, re-fetch the currency list to display the NavBar Currency Dropdown list
                    dispatch(
                        'settings/currency',
                        { is_active: true },
                        { root: true }
                    )
                    return true
                })
                .catch((_) => {
                    return false
                })
            return response
        } catch (error) {}
    },
    async recaptchaSettings({ commit }, params) {
        const response = await this.$axios
            .get('/website-setting/recaptcha-setting', { params })
            .then((response) => {
                commit('setRecaptchaSetting', response.data?.value)
                return response.data
            })
            .catch(() => false)
        return response
    },
    async updateRecaptchaSettingScore({ commit, state }, body) {
        const response = await this.$axios
            .put('/website-setting/recaptcha-setting/score', body)
            .then((response) => {
                commit('setRecaptchaSetting', {
                    ...state.recaptchaSetting,
                    ...body,
                })
                return response.data
            })
            .catch(() => false)
        return response
    },
    async updateRecaptchaSettingStatus({ commit, state }, body) {
        const response = await this.$axios
            .put('/website-setting/recaptcha-setting/status', body)
            .then((response) => {
                commit('setRecaptchaSetting', {
                    ...state.recaptchaSetting,
                    ...body,
                })
                return response.data
            })
            .catch(() => false)
        return response
    },
}

// Mutations
export const mutations = {
    websiteSettings(state, data) {
        state.websiteSettings = data
    },
    loading(state, type) {
        state.loading = type
    },
    adminRiskControls(state, data) {
        state.adminRiskControls = data
    },
    depositTurnovers(state, data) {
        state.depositTurnovers = data
    },
    setSettingTabCancelToken: (state, data) => {
        const oldData = { ...state.settingTabCancelToken }
        state.settingTabCancelToken = {
            ...oldData,
            ...data,
        }
    },
    suspendRegistration(state, data) {
        state.suspendRegistration = data
    },
    depositFlow(state, data) {
        state.depositFlow = data
    },
    playerRegistration(state, data) {
        state.playerRegistration = data
    },
    promotionClaimSetting(state, data) {
        state.promotionClaimSettingCount = data
    },
    setPromotionClaimSettingCountTemp(state, data) {
        state.promotionClaimSettingCountTemp = data
    },
    setPromotionSetting(state, value) {
        state.promotionClaimSettingCount = value
    },
    setCurrencies(state, value) {
        state.currencies = value
    },
    setRecaptchaSetting(state, value) {
        const data = {
            is_active: value.is_active,
            min_score: value.min_score,
        }
        state.recaptchaSetting = data
    },
}
function checkNotNull(input) {
    if (input === null) return false
    return true
}
